// types
import type { Notice } from '@revolutionprep/types'

// logger
import consolaLogger from 'consola'

export function usePusher () {
  /**
   * runtime config
   * ==================================================================
   */
  const config = useRuntimeConfig()

  /**
   * nuxt app
   * ==================================================================
   */
  const { $pusher } = useNuxtApp()

  /**
   * logger
   * ==================================================================
   */
  const logger = consolaLogger.withTag('Pusher')

  /**
   * computed
   * ==================================================================
   */
  const isDev = computed(() => {
    return config.public.appStage !== 'production'
  })

  /**
   * methods
   * ==================================================================
   */
  function initializePusher () {
    if (process.client) {
      $pusher.init()
    }
  }

  function subscribeToGlobalChannels (userId: number) {
    const privateChannelName = `private-revolution-user-${userId}`

    $pusher.bindEvent(
      'new-notice',
      privateChannelName,
      (data: { notice: Notice }) => {
        if (isDev) {
          logger.info(privateChannelName, 'new-notice', data)
        }
      }
    )
  }

  function unsubscribeFromGlobalChannels (userId: number) {
    const privateChannelName = `private-revolution-user-${userId}`

    $pusher.unbindEvent('new-notice', privateChannelName)

    $pusher.unsubscribeChannel(privateChannelName)
  }

  return {
    initializePusher,
    subscribeToGlobalChannels,
    unsubscribeFromGlobalChannels
  }
}
