import { default as indexiLk5WpPzQYMeta } from "/vercel/path0/apps/admin/src/pages/account/index.vue?macro=true";
import { default as _91consultId_93pYEMcJ1L3hMeta } from "/vercel/path0/apps/admin/src/pages/consults/[consultId].vue?macro=true";
import { default as index90qwlTXi9mMeta } from "/vercel/path0/apps/admin/src/pages/consults/index.vue?macro=true";
import { default as indexY8L1A8GSZOMeta } from "/vercel/path0/apps/admin/src/pages/courses/index.vue?macro=true";
import { default as index4DB8yRWaV7Meta } from "/vercel/path0/apps/admin/src/pages/index.vue?macro=true";
import { default as loginxW6FunB0boMeta } from "/vercel/path0/apps/admin/src/pages/login.vue?macro=true";
import { default as secretMd2P0MKduvMeta } from "/vercel/path0/apps/admin/src/pages/secret.vue?macro=true";
import { default as indexFfUX3EOAGqMeta } from "/vercel/path0/apps/admin/src/pages/students/[studentId]/index.vue?macro=true";
import { default as profilePTxg9OdIH0Meta } from "/vercel/path0/apps/admin/src/pages/students/[studentId]/profile.vue?macro=true";
import { default as indexy0AHhjQ1V2Meta } from "/vercel/path0/apps/admin/src/pages/students/index.vue?macro=true";
import { default as newyOGnbz6yssMeta } from "/vercel/path0/apps/admin/src/pages/students/new.vue?macro=true";
import { default as indexxZfxCxReikMeta } from "/vercel/path0/apps/admin/src/pages/tutor-packages/[tutorPackageId]/index.vue?macro=true";
import { default as matchesqbLgk8qEApMeta } from "/vercel/path0/apps/admin/src/pages/tutor-packages/[tutorPackageId]/matches.vue?macro=true";
import { default as indexoArpLvT1TpMeta } from "/vercel/path0/apps/admin/src/pages/tutor-packages/index.vue?macro=true";
export default [
  {
    name: indexiLk5WpPzQYMeta?.name ?? "account",
    path: indexiLk5WpPzQYMeta?.path ?? "/account",
    meta: indexiLk5WpPzQYMeta || {},
    alias: indexiLk5WpPzQYMeta?.alias || [],
    redirect: indexiLk5WpPzQYMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91consultId_93pYEMcJ1L3hMeta?.name ?? "consults-consultId",
    path: _91consultId_93pYEMcJ1L3hMeta?.path ?? "/consults/:consultId()",
    meta: _91consultId_93pYEMcJ1L3hMeta || {},
    alias: _91consultId_93pYEMcJ1L3hMeta?.alias || [],
    redirect: _91consultId_93pYEMcJ1L3hMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/consults/[consultId].vue").then(m => m.default || m)
  },
  {
    name: index90qwlTXi9mMeta?.name ?? "consults",
    path: index90qwlTXi9mMeta?.path ?? "/consults",
    meta: index90qwlTXi9mMeta || {},
    alias: index90qwlTXi9mMeta?.alias || [],
    redirect: index90qwlTXi9mMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/consults/index.vue").then(m => m.default || m)
  },
  {
    name: indexY8L1A8GSZOMeta?.name ?? "courses",
    path: indexY8L1A8GSZOMeta?.path ?? "/courses",
    meta: indexY8L1A8GSZOMeta || {},
    alias: indexY8L1A8GSZOMeta?.alias || [],
    redirect: indexY8L1A8GSZOMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: index4DB8yRWaV7Meta?.name ?? "index",
    path: index4DB8yRWaV7Meta?.path ?? "/",
    meta: index4DB8yRWaV7Meta || {},
    alias: index4DB8yRWaV7Meta?.alias || [],
    redirect: index4DB8yRWaV7Meta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginxW6FunB0boMeta?.name ?? "login",
    path: loginxW6FunB0boMeta?.path ?? "/login",
    meta: loginxW6FunB0boMeta || {},
    alias: loginxW6FunB0boMeta?.alias || [],
    redirect: loginxW6FunB0boMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: secretMd2P0MKduvMeta?.name ?? "secret",
    path: secretMd2P0MKduvMeta?.path ?? "/secret",
    meta: secretMd2P0MKduvMeta || {},
    alias: secretMd2P0MKduvMeta?.alias || [],
    redirect: secretMd2P0MKduvMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: indexFfUX3EOAGqMeta?.name ?? "students-studentId",
    path: indexFfUX3EOAGqMeta?.path ?? "/students/:studentId()",
    meta: indexFfUX3EOAGqMeta || {},
    alias: indexFfUX3EOAGqMeta?.alias || [],
    redirect: indexFfUX3EOAGqMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/students/[studentId]/index.vue").then(m => m.default || m)
  },
  {
    name: profilePTxg9OdIH0Meta?.name ?? "students-studentId-profile",
    path: profilePTxg9OdIH0Meta?.path ?? "/students/:studentId()/profile",
    meta: profilePTxg9OdIH0Meta || {},
    alias: profilePTxg9OdIH0Meta?.alias || [],
    redirect: profilePTxg9OdIH0Meta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/students/[studentId]/profile.vue").then(m => m.default || m)
  },
  {
    name: indexy0AHhjQ1V2Meta?.name ?? "students",
    path: indexy0AHhjQ1V2Meta?.path ?? "/students",
    meta: indexy0AHhjQ1V2Meta || {},
    alias: indexy0AHhjQ1V2Meta?.alias || [],
    redirect: indexy0AHhjQ1V2Meta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/students/index.vue").then(m => m.default || m)
  },
  {
    name: newyOGnbz6yssMeta?.name ?? "students-new",
    path: newyOGnbz6yssMeta?.path ?? "/students/new",
    meta: newyOGnbz6yssMeta || {},
    alias: newyOGnbz6yssMeta?.alias || [],
    redirect: newyOGnbz6yssMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/students/new.vue").then(m => m.default || m)
  },
  {
    name: indexxZfxCxReikMeta?.name ?? "tutor-packages-tutorPackageId",
    path: indexxZfxCxReikMeta?.path ?? "/tutor-packages/:tutorPackageId()",
    meta: indexxZfxCxReikMeta || {},
    alias: indexxZfxCxReikMeta?.alias || [],
    redirect: indexxZfxCxReikMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/tutor-packages/[tutorPackageId]/index.vue").then(m => m.default || m)
  },
  {
    name: matchesqbLgk8qEApMeta?.name ?? "tutor-packages-tutorPackageId-matches",
    path: matchesqbLgk8qEApMeta?.path ?? "/tutor-packages/:tutorPackageId()/matches",
    meta: matchesqbLgk8qEApMeta || {},
    alias: matchesqbLgk8qEApMeta?.alias || [],
    redirect: matchesqbLgk8qEApMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/tutor-packages/[tutorPackageId]/matches.vue").then(m => m.default || m)
  },
  {
    name: indexoArpLvT1TpMeta?.name ?? "tutor-packages",
    path: indexoArpLvT1TpMeta?.path ?? "/tutor-packages",
    meta: indexoArpLvT1TpMeta || {},
    alias: indexoArpLvT1TpMeta?.alias || [],
    redirect: indexoArpLvT1TpMeta?.redirect,
    component: () => import("/vercel/path0/apps/admin/src/pages/tutor-packages/index.vue").then(m => m.default || m)
  }
]